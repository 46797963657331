<template>
  <v-row class="fill-height py-0">
    <v-col class="py-0 pl-0 pr-4 pr-lg-0 d-none d-lg-flex tree-menu-width"
           cols="12"
           lg="2">
      <slot name="left"/>
    </v-col>
      <v-row>
        <v-col class="pb-2 pt-0" height="100vh">
          <slot name="right"/>
        </v-col>
      </v-row>
  </v-row>
</template>

<script>
export default {
  name: 'TemplateFeed.vue',
  computed: {
    view() {
      return this.$store.state.view
    }
  }
};

</script>

<style>
.tree-menu-width {
  min-width: 400px;
  max-width: 400px;
  max-height: calc(100vh - 124px);
}
.botom-menu-width{
  min-width: 274px;
  max-width: 274px;
}
</style>