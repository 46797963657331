<template>
  <v-container class="d-flex align-center justify-center height-100">
    <v-card class="d-flex align-center justify-center flex-column register-container dense">
      <img src="../assets/control_panel_icon_512 1 1@2x.png" class="logo mt-10">
      <span class="title-text font-weight-regular mt-4">NSoft Application Builder - Sign In</span>
      <p class="description-text text-center grey--text text--darken-1 mt-4 mb-10">
        Welcome to NSoft Application builder, platform for application register.
      </p>
      <v-card-text class="pa-0">
        <v-form v-model="formValid" @submit.prevent="loginUser" class="mt-4">
          <v-text-field
              v-model="form.email_address"
              height="40"
              label="Email"
              type="email"
              :rules="emailRules"
              @input="loginError = []"
              :error="loginError.length > 0"
              :error-messages="loginError"
              dense
              outlined
          ></v-text-field>
          <v-text-field
              v-model="form.password"
              height="40"
              label="Password"
              @click:append="showPassword = !showPassword"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? icons.mdiEye : icons.mdiEyeOff"
              :rules="passwordRules"
              dense
              @input="loginError = []"
              :error="loginError.length > 0"
              :error-messages="loginError"
              outlined
          ></v-text-field>
          <router-link to="/forgot-password" class="text-decoration-none font-weight-bold float-right">Forgot
            Password?
          </router-link>
          <v-btn type="submit" color="primary" class="mt-16 text-capitalize letter-spacing-normal" block
                 :disabled="!formValid">Sign In
          </v-btn>
        </v-form>
      </v-card-text>

    </v-card>
  </v-container>
</template>

<script>
import {mapActions} from "vuex";
import { mdiEye, mdiEyeOff } from "@mdi/js";
//import IAM from "../services/IAM";
export default {
  metaInfo: {
    title: 'Sign In',
    meta: [
      {
        name: 'description',
        content: 'NSoft Devices - Sign In'
      }
    ]
  },
  data: () => ({
    icons: {
      mdiEye: mdiEye,
      mdiEyeOff: mdiEyeOff
    },
    form: {
      email_address: "",
      password: "",
    },
    loginError: [],
    showPassword: "",
    formValid: false,
    rules: {
      required: v => !!v || "Required",
    },
    passwordRules: [
      v => !!v || 'Password is required',
      v => (v && v.length >= 8) || 'Password must have at least 8 characters.',
      v => /(?=.*[A-Z])/.test(v) || 'Must have one uppercase character',
      v => /(?=.*\d)/.test(v) || 'Must have one number',
      v => /([!@$%])/.test(v) || 'Must have one special character [!@#$%]',
    ],
    emailRules: [
      v => !!v || 'Email is required',
      v => /.+@.+/.test(v) || 'Email is invalid',
    ],
  }),
  methods: {
    ...mapActions({"login": "iam/loginUser", "getUser": "accounts/getUser", "expiresIn": "expiresIn"}),
    loginUser() {
      this.$store.commit("SET_USER_EMAIL", this.form.email_address);
      this.login(this.form).then(() => {
        window.location.href = '/';
      }).catch(err => {
        this.loginError.push(err.error_description)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.title-text {
  font-size: 20px;
  letter-spacing: 0;
  line-height: normal;
}

.description-text {
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: normal;
}

.height-100 {
  height: 100vh;
}

.register-container {
  padding-left: 64px;
  padding-right: 64px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  max-width: 440px;
  padding-bottom: 64px;

  .logo {
    width: 56px;
    height: 56px;
  }

  .g-signin-button {
    display: flex;
    box-shadow: 0 2px 7px 0 rgba(34, 34, 34, 0.15);
    padding-top: 11px;
    padding-bottom: 11px;
    width: 100%;
    align-items: center;
    cursor: pointer;
    justify-content: center;

    img {
      width: 18px;
      height: 18px;
      margin-right: 22px;
    }

    /* This is where you control how the button looks. Be creative! */

  }
}
</style>
