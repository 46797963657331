<template>
  <v-navigation-drawer
      app
      color="blackish"
      width="100"
      dark
      class="nav-drawer"
      mobile-breakpoint="600"
  >
    <div class="d-none d-sm-flex justify-center ma-4">
        <img
            height="48"
            src="../assets/logo.png"
            alt="logo"/>
    </div>
    <v-list class="pa-0">
      <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          router
          class="nav-list"
          :class="{'bg-color': routeName === 'Applications' && item.index === 0}"
          active-class="bg-color"
      >
      <v-list-item-content class="py-2">
          <v-icon class="mb-2">
            {{ item.icon }}
          </v-icon>
          <v-list-item-title class="caption font-weight-medium text-center">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mdiApplicationCogOutline } from "@mdi/js";
export default {
  data() {
    return {
      items: [
        {
          index: 0,
          title: 'Apps',
          icon: mdiApplicationCogOutline,
          route: '/applications'
        },
      ],
    };
  },
  watch: {},
  computed: {
   routeName() {
     return this.$route.name;
   },
  },
};
</script>

<style
    lang="scss"
    scoped>
.nav-list {
  height: 64px;
}

.bg-color, .performance-class, .events-class, .logs-class {
  background-color: rgba(255, 255, 255, 0.08);
}

.bgrd_color {
  background-color:var(--v-blackish-base);
}

.v-navigation-drawer {
  z-index: 999;
}

::v-deep {
  .v-list-item--active::before {
    display: none;
  }
}

.nav-drawer {
  z-index: 900;
}
</style>