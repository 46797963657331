<template>
    <div class="pa-3 ">
      <v-text-field
        label="Search"
        outlined
        dense
        append-icon="mdi-magnify"
      ></v-text-field>
      <v-list class="pa-0">
        <v-card
          outlined
          class="mb-4 app"
        >
          <v-card-title>
            <div class="icon">
              <img src="../assets/nsoft.png" />
            </div>
            <div class="ml-4 version-info d-flex justify-center flex-column">
              <span
                class="font-weight-bold subtitle-1 d-inline-block text-truncate"
              >name</span>
              <span class="text--secondary">version</span>
            </div>
          </v-card-title>
        </v-card>
      </v-list>
    </div>
  </template>
  
  <script>
  

  export default {
    
  };
  </script>
  
  <style scoped lang="scss">
  .app {
    cursor: pointer;
  }
  .app:hover {
    border: 1px solid #c0c0c0;
  }
  .icon {
    padding: 20px;
    border-radius: 5px;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    border: 1px solid #dedede;
    justify-content: center;
    img {
      width: 64px;
      height: 64px;
    }
  }
  .version-info {
    word-break: break-all;
    width: calc(100% - 80px);
  }
  </style>