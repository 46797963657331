<template>
  <div>
    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0" cols="10"><v-card-title class="">Application name</v-card-title></v-col>
      <v-col class="ma-0 pa-0" cols="2"><v-row justify="end" class="mt-4 mr-0"><v-btn color="primary" small>Save</v-btn></v-row></v-col>
    </v-row>
    <vue-json-editor
        :disabled="true"
        mode="code"
        v-model="appConfig"
        :expandedOnStart="true"
    ></vue-json-editor>
  </div>
</template>
<script>
import VueJsonEditor from "vue-json-editor"
import {mapActions, mapGetters} from "vuex";
export default {
  name: "JSONPreview",
  components: {
    VueJsonEditor
  },
  mounted: function() {
        var editors = document.getElementsByClassName('jsoneditor-vue');
        for (var i = 0; i < editors.length; i++) {
            editors[i].style.height = '790px';
        }
    },
  methods: {
    ...mapActions(["togglePossibilitiesModal"]),
    handleOptionField(e) {
      if (e.keyCode === 13) {
        this.currentLayer.possibilities.push(this.newOption)
        this.newOption = ""
      }
    },
    removePossibility(i) {
      this.currentLayer.possibilities.splice(i, 1);
    }
  },
  data: () => ({
    newOption: "",
  }),
  computed: {
    ...mapGetters(["possibilitiesModal"]),
    currentLayer: {
      deep: true,
      get: function() {
        return this.$store.getters.currentLayer
      },
      set: function(val) {
        return this.$store.dispatch('setCurrentLayer', val);
      }
    },
    appConfig: {
      get: function() {
        return this.$store.getters.appConfig
      },
      set: function(val) {
        return this.$store.dispatch('updateAppConfig', val);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.json-preview {
  width: 100%;
}

</style>
