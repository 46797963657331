<template>
  <div>
    <left-sidebar v-if="noSidebarRoutes.indexOf($route.name) === -1"/>
    <v-main class="container-padding  overflow-y-lg-hidden">
      <v-container class="pt-0 pl-0" fluid>
        <v-row>
      <v-tabs v-if="noSidebarRoutes.indexOf($route.name) === -1" background-color="white" height="64px" style="border-bottom: 1px solid #dedede;" class="tab-container">
          <h3 class="capitalize Text-Style-4 pl-5 mt-5 ml-4">
          {{ this.$route.name }}
          </h3>
      </v-tabs>
    </v-row>
        <router-view></router-view>
      </v-container>
    </v-main>
    <right-sidebar v-if="noSidebarRoutes.indexOf($route.name) === -1"/>
  </div>
</template>

<script>
import RightSidebar from '../components/RightSidebar';
import LeftSidebar from '../components/LeftSidebar';
export default {
    name: 'TemplateDefault',
    components:{
        RightSidebar,
        LeftSidebar
    },
    data: () => ({
    noSidebarRoutes: ['Login']
  }),
}
</script>

<style>
.container-padding {
  padding: 0 64px 0 100px;
}

@media only screen and (min-width: 1264px) {
  .overflow-y-lg-hidden {
    max-height: 100vh;
    overflow-y: hidden;
  }
}

@media only screen and (max-width: 600px) {
  .container-padding {
    padding: 0 !important;
  }
}
.pt-0 {
    background-color: aliceblue;
}
.Text-Style-4 {
  color: #222222 !important;
}
</style>