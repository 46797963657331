<script src="router/index.js"></script>
<template>
  <v-app>
    <TemplateDefault/>
  </v-app>
</template>
<script>

import TemplateDefault from "./layouts/TemplateDefault";


export default {
  data: () => ({
    showND: false,
    timer: null,
  }),
  components: {
    TemplateDefault,
  },
  metaInfo: {
    title: "Application Builder",
    titleTemplate: "%s | NSoft Devices",
  },
};
</script>
<style lang="scss">
.progress_bar{
  position:absolute;
  z-index: 1000;
}
.env {
  position: fixed;
  bottom: 0;
}
.row {
  margin-top: 0;
  margin-bottom: 0;
}

.row + .row {
  margin-top: 0;
}

* {
  outline: none;
}

::-webkit-scrollbar {
  width: 0 !important;
}

.theme--light.v-divider {
  border-color: var(--v-lineColor-base) !important;
  z-index: 200;
}
.theme--dark.v-divider {
  z-index: 900;
}

.text-primary {
  color: var(--v-textColor-base) !important;
}

.text-secondary {
  color: var(--v-textColor-lighten4) !important;
}
.text-warnning {
  color: var(--v-textColor-red-darken-2) !important;
}

.alert--bottom-fixed {
  width: 100%;
  z-index: 999;
  bottom: 0px;
  position: fixed !important;
  pointer-events: none !important;
  
}

.v-navigation-drawer__border {
  background-color: transparent !important;
}

.v-navigation-drawer {
  transition-duration: 0.35s !important;
}

.side-menu {
  right: 64px;
}

.top-initial {
  top: initial !important;
}

.centered-input * {
  text-align: center;
}

.centered-input .v-input__append-outer {
  margin-top: 0px !important;
}


.v-expansion-panel:before {
  box-shadow: none !important;
}

.subtitle-2 {
  line-height: 16px !important;
}

.body-text {
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
}

.letter-spacing-normal {
  letter-spacing: normal !important;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
> .v-input__control
> .v-input__slot
fieldset {
  border: thin solid rgba(34, 34, 34, 0.2);
}


@media screen and (min-width: 600px) {
  .overflow-md-y-hidden {
    overflow-y: hidden;
  }

  .content-height {
    max-height: calc(100vh - 177px);
  }
}
</style>
