import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    types: {
      text_input: {
        "name": "Example input",
        "deterministic_id": "example_input",
        "placeholder_text": "Example placeholder",
        "type": "text_input",
        "possibilities": [],
        "default_value": "example_value"
      },
      select: {
        "name": "Example select",
        "deterministic_id": "example_select",
        "placeholder_text": "Example placeholder",
        "type": "select",
        "default_value": "option-1",
        "possibilities": [
          {
            name: "Option 1",
            value: "option-1"
          },
          {
            name: "Option 2",
            value: "option-2"
          },
          {
            name: "Option 3",
            value: "option-3"
          },
          {
            name: "Option 4",
            value: "option-4"
          }
        ]
      },
      radio: {
        "name": "Example radio",
        "deterministic_id": "example_radio",
        "placeholder_text": "",
        "type": "radio",
        "default_value": "Option 1",
        "possibilities": [
          {
            name: "Option 1",
            value: "option-1"
          },
          {
            name: "Option 2",
            value: "option-2"
          },
          {
            name: "Option 3",
            value: "option-3"
          },
          {
            name: "Option 4",
            value: "option-4"
          }
        ]
      },
      toggle: {
        "name": "",
        "type": "desktop",
        "product": "",
        "product_versions": [],
        "description": "",
        "url": "",
        "vendor": "NSoft",
        "package_base_name": "",
        "runtimes": [],
        "config": {
          "header": "",
          "description": "",
          "configurable_options": []
        }
      }
    },
    possibilitiesModal: false,
    selectedLayerIndex: null,
    appConfig: {
      header: "Example app",
      description: "This is an example app.",
      configurable_options: [
        {
          "name": "Url you want to display:",
          "deterministic_id": "",
          "placeholder_text": "displayUrl",
          "type": "text_input",
          "possibilities": [],
          "default_value": "www.nsoft.com"
        },
      ]
    }
  },
  getters: {
    layers(state) {
      return state.appConfig.configurable_options
    },
    header(state) {
      return state.appConfig.header
    },
    description(state) {
      return state.appConfig.description
    },
    currentLayer(state) {
      return state.appConfig.configurable_options[state.selectedLayerIndex]
    },
    possibilitiesModal(state) {
      return state.possibilitiesModal
    },
    appConfig(state) {
      return state.appConfig
    }
  },
  mutations: {
    SET_DESCRIPTION(state, value) {
      state.appConfig.description = value;
    },
    SET_HEADER(state, value) {
      state.appConfig.header = value;
    },
    REMOVE_LAYER(state, index) {
      state.appConfig.configurable_options.splice(index, 1);
    },
    ADD_LAYER(state, value) {
      console.log(value);
      state.appConfig.configurable_options.push(value);
    },
    SET_CURRENT_LAYER(state, value) {
      state.selectedLayerIndex = value;
    },
    TOGGLE_POSSIBILITIES_MODAL(state) {
      state.possibilitiesModal = !state.possibilitiesModal;
    },
    UPDATE_CURRENT_LAYER(state, value) {
      state.appConfig.configurable_options[state.selectedLayerIndex] = value;
    },
    UPDATE_APP_CONFIG(state, value) {
      state.appConfig = value;
    }
  },
  actions: {
    selectLayer({commit}, val) {
      commit('SET_CURRENT_LAYER', val);
    },
    setDescription({commit}, val) {
      commit('SET_DESCRIPTION', val)
    },
    setHeader({commit}, val) {
      commit('SET_HEADER', val);
    },
    removeLayer({commit}, i) {
      commit('REMOVE_LAYER', i);
    },
    addLayer({commit, rootState}, type) {
      commit('ADD_LAYER', rootState.types[type])
    },
    togglePossibilitiesModal({commit}) {
      commit('TOGGLE_POSSIBILITIES_MODAL');
    },
    setCurrentLayer({commit}, val) {
      commit('UPDATE_CURRENT_LAYER', val);
    },
    updateAppConfig({commit}, val) {
      commit('UPDATE_APP_CONFIG', val);
    }
  },
  modules: {
  }
})
