<template>
  <v-navigation-drawer
      app
      color="blackish"
      width="64"
      dark
      right
      mobile-breakpoint="600"
      style="z-index: 999;"
  >
    <v-list
        dense
        nav
        class="py-1"
    >
      <v-list-item class="px-1">
        <v-list-item-avatar @click="toggleModal" style="cursor: pointer">
          <img
              src="../assets/avatar.png"
              alt="avatar"/>
        </v-list-item-avatar>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import {mapActions} from "vuex";
import {mdiWeatherNight, mdiWeatherSunset} from "@mdi/js";

export default {
  data() {
    return {
      item: 0,
      icons: {
        mdiNight: mdiWeatherNight,
        mdiDay: mdiWeatherSunset,
      }
      // items: [
      //   {
      //     title: 'Directory',
      //     icon: 'mdi-folder',
      //   },
      //   {
      //     title: 'Assets',
      //     icon: 'mdi-briefcase',
      //   },
      // ],
    };
  },
  methods: {
    ...mapActions({"toggleAccountModal": "accounts/toggleAccountModal"}),
    toggleModal() {
      this.toggleAccountModal();
    }
  }
};
</script>

<style scoped>
</style>
