<template>
  <div>
    <v-dialog v-model="possibilitiesModal" width="500"  @click:outside="togglePossibilitiesModal">
      <v-card width="500" height="520">
        <v-card-title class="d-flex justify-space-between align-center pl-3 pr-1">
          <span>Create new app</span>
          <v-btn icon @click="togglePossibilitiesModal"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
              <vue-json-editor
              class="px-2"
              mode="code"
              v-model="newAppJson"
              >
            </vue-json-editor>
            <v-card-actions class="mr-0 pr-2 mt-2">
          <v-spacer></v-spacer>
          <v-btn
            color="grey lighten-3"
            small
            depressed
            class="text-capitalize"
            @click="togglePossibilitiesModal"
            >Cancel</v-btn
          >
          <v-btn
            color="blue"
            small
            depressed
            class="white--text text-capitalize"
            >Create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import VueJsonEditor from "vue-json-editor"
  export default {
    name: 'HelloWorld',
    components:{
      VueJsonEditor
    },
    computed: {
      ...mapGetters(["description", "header", "layers", "possibilitiesModal"]),
    },
    methods: {
      ...mapActions(["togglePossibilitiesModal"]),
      handleOptionField(e) {
        if (e.keyCode === 13) {
          this.addOption()
          this.newOption = {}
        }
      },
      addOption() {
        this.currentLayer.possibilities.push(this.newOption)
        this.newOption = {}
      },
      removePossibility(i) {
        this.currentLayer.possibilities.splice(i, 1);
      }
    },
    data: () => ({
      newOption: {
        name: "",
        value: ""
      },
      newAppJson:{
        "name": "",
        "type": "desktop",
        "product": "",
        "product_versions": [],
        "description": "",
        "url": "",
        "vendor": "NSoft",
        "package_base_name": "",
        "runtimes": [],
        "config": {
          "header": "",
          "description": "",
          "configurable_options": []
        }
      }
    })
  }
</script>

<style>
.modal-preview {
  width: 100%;
  height: calc(100vh - 48px);
}
.letter-spacing-normal {
  letter-spacing: normal;
}
.jsoneditor-vue {
    height: 400px;
}
.editor {
    height: 400px;
}
</style>
