<template>
  <div>
  <TemplateFeed>
    <v-card slot="left"
      class="ml-3"
      width="100%"
      height="100vh"
      elevation="0"
      style="border-radius: 0px; border-right: 1px solid #dedede;">
            <FeedApplications/>
            <v-btn
              fab
              style="margin-bottom:110px;"
              color="primary"
              absolute
              bottom
              right
              v-on:click="toggle">
              <v-icon>{{ icons.mdiPlus }}</v-icon>
            </v-btn>
    </v-card>
    <v-card slot="right" class="pa-4 ma-4" height="90vh" elevation="0" outlined>
      <JSONPreview/>
    </v-card>
    <ModalPreview slot="right"/>
  </TemplateFeed>
  </div>
</template>
<script>
import { mdiPlus } from '@mdi/js'
import JSONPreview from '../components/JSONPreview.vue'
import TemplateFeed from '../layouts/TemplateFeed.vue'
import ModalPreview from '../components/ModalPreview.vue'
import FeedApplications from '../components/FeedApplications.vue'



export default {
  name: 'Home',
  components: {
    JSONPreview,
    TemplateFeed,
    ModalPreview,
    FeedApplications
  },
  data(){
    return{
      icons:{
        mdiPlus: mdiPlus
      },
      spanVisible: true,
    }

  },
  watch: {
    spanVisible:{
      handler(val){
        this.$store.commit('TOGGLE_POSSIBILITIES_MODAL', val)
      },
      deep: true,
    }
  },
  methods: {
    toggle () {
        this.spanVisible = !this.spanVisible
    }
}
}
</script>

<style>
.report-card{
  border-radius: 0px;
}
</style>